import React from "react";

import {
  Box,
  FlexCol,
  FlexGrid,
  H6,
  PrismicImage,
  SectionWrapper,
  Text,
  PrismicLink,
  LargeButton
} from "src/components";

function UseCases({ useCases, rentalLink, availabilityLink, buttonText }) {
  return (
    <SectionWrapper id="use-cases" py={[8, 9, 10]} bg="bg.default">
      <div
        css={`
          padding: 0 ${props => props.theme.space[5] + "px"};
          border: 1px solid ${props => props.theme.colors.bg.alt};
          border-radius: 4px;
          text-align: center;
          ${props => props.theme.mediaQueries.medium} {
            padding: 0 ${props => props.theme.space[7] + "px"};
          }
        `}
      >
        <div
          css={`
            display: inline-block;
            padding: 0 ${props => props.theme.space[3] + "px"};
            background-color: ${props => props.theme.colors.bg.default};
            transform: translateY(-50%);
          `}
        >
          <H6 children={`Rent the hall for`} textAlign="center" />
        </div>
        {useCases && (
          <Box my={[5, 6]}>
            <FlexGrid gutterX={[6, 7]} gutterY={[5]}>
              {useCases.map((item, index) => (
                <Box
                  width={[1 / 2, null, 1 / 4]}
                  key={"packingItems" + index}
                  alignItems="center"
                >
                  {item.icon && <PrismicImage image={item.icon} />}
                  <Text
                    children={item.case}
                    mt={2}
                    fontSize={0}
                    textAlign="center"
                  />
                </Box>
              ))}
            </FlexGrid>
          </Box>
        )}
        <div
          css={`
            display: inline-block;
            padding: 0 ${props => props.theme.space[3] + "px"};
            background-color: ${props => props.theme.colors.bg.default};
            transform: translateY(50%);
          `}
        >
          <H6 children={`and much more`} textAlign="center" />
        </div>
      </div>
      {buttonText && rentalLink && availabilityLink && (
        <FlexCol alignItems="center" mt={[5, 6]}>
          <PrismicLink link={rentalLink}>
            <LargeButton children={buttonText} variant="primary" />
          </PrismicLink>
          <H6 children={"Before you book..."} mt={4} mb={2} />
          <Text
            children={`Check if your day is available`}
            as="a"
            href="https://calendar.google.com/calendar/embed?src=calgaryfolkfest.com_4kf9l8f6s3s2jf55f8n0t36gu4%40group.calendar.google.com&ctz=America%2FEdmonton"
            target="_blank"
            rel="noopener noreferrer"
            fontSize={1}
            style={{ textDecoration: "underline" }}
          />
        </FlexCol>
      )}
    </SectionWrapper>
  );
}

export default UseCases;
