import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { SEO } from "src/components";

import Intro from "./_components/_intro";
import UseCases from "./_components/_useCases";
import Space from "./_components/_space";
import Pricing from "./_components/_pricing";
import FAQ from "./_components/_faq";
import CTA from "./_components/_cta";

const FestivalHall = ({ setInvert, setInvertLogo, preview }) => (
  <StaticQuery
    query={graphql`
      {
        festivalHall: prismicFestivalHall {
          dataString
        }
      }
    `}
    render={data => {
      let page = JSON.parse(data.festivalHall.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={
              page.opengraph_title && RichText.asText(page.opengraph_title)
            }
            description={
              page.opengraph_description &&
              RichText.asText(page.opengraph_description)
            }
          />
          <Intro
            subtitle={page.intro && RichText.asText(page.intro)}
            image={page.intro_image}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          <UseCases
            useCases={page.use_cases}
            buttonText={page.closing_cta_button_text}
            rentalLink={page.rental_link}
            availabilityLink={page.rental_availability_link}
          />
          <Space
            heading={page.space_heading && RichText.asText(page.space_heading)}
            description={
              page.space_description &&
              RichText.render(page.space_description, linkResolver, serializer)
            }
            image1={page.space_image_1}
            image2={page.space_image_2}
          />
          <Pricing
            heading={
              page.pricing_heading && RichText.asText(page.pricing_heading)
            }
            description={
              page.pricing_description &&
              RichText.render(
                page.pricing_description,
                linkResolver,
                serializer
              )
            }
            pricingItems={page.items}
          />
          <FAQ
            heading={page.faqs_heading && RichText.asText(page.faqs_heading)}
            description={
              page.faqs_description &&
              RichText.render(page.faqs_description, linkResolver, serializer)
            }
            faqs={page.body && page.body.length > 0 && page.body}
          />
          <CTA
            header={
              page.closing_cta_header &&
              RichText.asText(page.closing_cta_header)
            }
            buttonText={page.closing_cta_button_text}
            rentalLink={page.rental_link}
            availabilityLink={page.rental_availability_link}
          />
        </>
      );
    }}
  />
);

export default FestivalHall;
