import React from "react";

import {
  Box,
  FlexRow,
  H6,
  SectionHeader,
  SectionWrapper,
  Text
} from "src/components";

const Pricing = ({ heading, description, pricingItems }) => (
  <SectionWrapper id="prices" py={[8, 9, 10]} bg="bg.wash">
    <SectionHeader heading={heading} body={description} />
    <Box mx={["-7.5vw", 0]}>
      <FlexRow
        justifyContent="flex-end"
        pb={3}
        px={["7.5vw", 0]}
        css={`
          align-items: flex-end;
          height: 128px;
          ${props => props.theme.mediaQueries.small} {
            height: auto;
          }
        `}
      >
        <FlexRow justifyContent="center" width={1 / 4}>
          <div
            css={`
              width: 128px;
              transform: rotate(-80deg) translate(32px, 0);
              transform-origin: center;
              ${props => props.theme.mediaQueries.small} {
                width: auto;
                text-align: center;
                transform: none;
              }
            `}
          >
            <H6 children="Nonprofit / charity" />
          </div>
        </FlexRow>
        <FlexRow justifyContent="center" width={1 / 4}>
          <div
            css={`
              width: 128px;
              transform: rotate(-80deg) translate(32px, 0);
              transform-origin: center;
              ${props => props.theme.mediaQueries.small} {
                width: auto;
                text-align: center;
                transform: none;
              }
            `}
          >
            <H6 children="Private / individual" />
          </div>
        </FlexRow>
        <FlexRow justifyContent="center" width={1 / 4}>
          <div
            css={`
              width: 128px;
              transform: rotate(-80deg) translate(32px, 0);
              transform-origin: center;
              ${props => props.theme.mediaQueries.small} {
                width: auto;
                text-align: center;
                transform: none;
              }
            `}
          >
            <H6 children="Wedding / corporate" />
          </div>
        </FlexRow>
      </FlexRow>
      {pricingItems.map(item => (
        <FlexRow
          py={3}
          px={["7.5vw", 0]}
          css={`
            border-bottom: 1px solid ${props => props.theme.colors.bg.alt};
          `}
        >
          <Box width={1 / 4}>
            <Text children={item.name} fontSize={[0, 1]} fontWeight={600} />
          </Box>
          <Box width={1 / 4}>
            <Text
              fontSize={[0, 1]}
              textAlign="center"
              color="text.body"
              css={`
                font-variant-numeric: tabular-nums;
              `}
            >
              {item.non_profit_price ? "$" + item.non_profit_price : ""}
            </Text>
          </Box>
          <Box width={1 / 4}>
            <Text
              fontSize={[0, 1]}
              textAlign="center"
              color="text.body"
              css={`
                font-variant-numeric: tabular-nums;
              `}
            >
              {item.individual_price ? "$" + item.individual_price : ""}
            </Text>
          </Box>
          <Box width={1 / 4}>
            <Text
              fontSize={[0, 1]}
              textAlign="center"
              color="text.body"
              css={`
                font-variant-numeric: tabular-nums;
              `}
            >
              {item.wedding__corporate ? "$" + item.wedding__corporate : ""}
            </Text>
          </Box>
        </FlexRow>
      ))}
    </Box>
  </SectionWrapper>
);

export default Pricing;
