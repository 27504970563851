import React from "react";
import { cover } from "polished";

import { RichText, H2, SectionWrapper, PrismicImage } from "src/components";

const gap = "80px";
const smallGap = "32px";

function Space({ heading, description, image1, image2 }) {
  return (
    <SectionWrapper id="space" maxWidth={2} py={[8, 9, 10]} bg="bg.reverse">
      <div
        css={`
          display: grid;
          -ms-grid-columns: 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr
            ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr;
          grid-template-columns: 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr;
          -ms-grid-rows: 1fr ${smallGap} 50vw ${smallGap} 50vw;
          grid-template-rows: 1fr ${smallGap} 50vw ${smallGap} 50vw;
          ${props => props.theme.mediaQueries.large} {
            -ms-grid-columns: 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr
              ${gap} 1fr ${gap} 1fr ${gap} 1fr;
            grid-template-columns: 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr;
            -ms-grid-rows: 1fr ${gap} minmax(320px, 15vw);
            grid-template-rows: 1fr ${gap} minmax(400px, 20vw);
          }
        `}
      >
        <div
          css={`
            -ms-grid-column: 1;
            -ms-grid-column-span: 15;
            grid-column: 1 / 16;
            -ms-grid-row: 1;
            grid-row: 1;
            ${props => props.theme.mediaQueries.large} {
              -ms-grid-column: 3;
              -ms-grid-column-span: 7;
              grid-column: 3 / 10;
              -ms-grid-row: 1;
              grid-row: 1;
            }
          `}
        >
          <H2 children={heading} color="text.reverse" />
          {description && (
            <RichText mt={[3, 4]} fontSize={[1, 2]} color="text.reverseBody">
              {description}
            </RichText>
          )}
        </div>
        <div
          css={`
            position: relative;
            -ms-grid-column: 1;
            -ms-grid-column-span: 12;
            grid-column: 1 / 13;
            -ms-grid-row: 3;
            grid-row: 3;
            background-color: #666;
            ${props => props.theme.mediaQueries.large} {
              -ms-grid-column: 11;
              -ms-grid-column-span: 5;
              grid-column: 11 / 16;
              -ms-grid-row: 1;
              -ms-grid-row-span: 3;
              grid-row: 1 / 4;
            }
            > * {
              height: 100%;
            }
          `}
        >
          <PrismicImage
            image={image1}
            style={{ ...cover(), width: "100%", height: "100%" }}
          />
        </div>
        <div
          css={`
            position: relative;
            -ms-grid-column: 4;
            -ms-grid-column-span: 12;
            grid-column: 4 / 16;
            -ms-grid-row: 5;
            grid-row: 5;
            background-color: #666;
            ${props => props.theme.mediaQueries.large} {
              -ms-grid-column: 1;
              -ms-grid-column-span: 9;
              grid-column: 1 / 10;
              -ms-grid-row: 3;
              grid-row: 3;
            }
            > * {
              height: 100%;
            }
          `}
        >
          <PrismicImage
            image={image2}
            style={{ ...cover(), width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </SectionWrapper>
  );
}

export default Space;
