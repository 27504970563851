import React from "react";

import {
  Box,
  FlexCol,
  FlexGrid,
  LargeButton,
  PageHeaderV2,
  PrismicImage,
  RichText,
  SectionWrapper,
  H6,
  PrismicLink,
  Text
} from "src/components";

function Intro({
  image,
  description,
  buttonText,
  availabilityLink,
  rentalLink,
  setInvert,
  setInvertLogo
}) {
  return (
    <>
      <PageHeaderV2
        heading={`Festival Hall`}
        links={["Space", "Prices", "FAQs"]}
        image={image}
        color="brand.altPrimary"
        invert={true}
        setInvertLogo={setInvertLogo}
        setInvert={setInvert}
      />
      <SectionWrapper>
        <FlexCol width={[1, 1 / 2, null, 1 / 3]}>
          <Box>
            {description && <RichText fontSize={2}>{description}</RichText>}
            {buttonText && rentalLink && availabilityLink && (
              <Box mt={5}>
                <PrismicLink link={rentalLink}>
                  <LargeButton children={buttonText} variant="primary" />
                </PrismicLink>
                <H6 children={"Before you book..."} mt={4} mb={2} />
                <Text
                  children={`Check if your day is available`}
                  as="a"
                  href="https://calendar.google.com/calendar/embed?src=calgaryfolkfest.com_4kf9l8f6s3s2jf55f8n0t36gu4%40group.calendar.google.com&ctz=America%2FEdmonton"
                  target="_blank"
                  rel="noopener noreferrer"
                  fontSize={1}
                  style={{ textDecoration: "underline" }}
                />
              </Box>
            )}
          </Box>
        </FlexCol>
      </SectionWrapper>
    </>
  );
}

export default Intro;
