import React, { useState } from "react";
import { cover } from "polished";

import {
  Box,
  FlexCol,
  FlexGrid,
  H2,
  H6,
  LargeButton,
  PrismicImage,
  PrismicLink,
  Text,
  SectionWrapper
} from "src/components";

function CTA({ header, image, rentalLink, availabilityLink, buttonText }) {
  return (
    <Box bg="brand.altPrimary">
      <SectionWrapper>
        <Box>
          <FlexGrid gutterX={[5, 7]}>
            <Box width={[1, 1 / 2]}>
              <Box py={[7, 9]}>
                {header && <H2 children={header} color="text.reverse" />}
                {buttonText && rentalLink && availabilityLink && (
                  <Box mt={4}>
                    <PrismicLink link={rentalLink}>
                      <LargeButton children={buttonText} />
                    </PrismicLink>
                    <H6
                      children={"Before you book..."}
                      mt={4}
                      mb={2}
                      color="text.reverse"
                    />
                    <Text
                      color="text.reverse"
                      children={`Check if your day is available`}
                      as="a"
                      href="https://calendar.google.com/calendar/embed?src=calgaryfolkfest.com_4kf9l8f6s3s2jf55f8n0t36gu4%40group.calendar.google.com&ctz=America%2FEdmonton"
                      target="_blank"
                      rel="noopener noreferrer"
                      fontSize={1}
                      style={{ textDecoration: "underline" }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {image && (
              <FlexCol display={["none", "flex"]} width={[1, 1 / 2]}>
                <FlexCol position="relative" flex={1} mr="-7.5vw">
                  <PrismicImage
                    image={image}
                    style={{ ...cover(), height: "100%" }}
                  />
                </FlexCol>
              </FlexCol>
            )}
          </FlexGrid>
        </Box>
      </SectionWrapper>
    </Box>
  );
}

export default CTA;
